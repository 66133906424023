

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class ServiceTrial extends Vue {
  @Prop({default: ''})
  title: string;

  @Prop({default: ''})
  text: string;

  @Prop({default: ''})
  cta: string;

  bgImage = require('@/assets/images/services/free_trials_bg.png');

}

export default ServiceTrial;
