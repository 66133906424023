














import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
class NavigationDropdown extends Vue {
  @Prop({default: false})
  isFullWidth: boolean;

  @Prop({default: false})
  isMobile: boolean;

  @Prop({default: true})
  isRight: boolean;

  isActive = false;

  close() {
    this.isActive = false;
  }

  handleClick() {
    this.isActive = !this.isActive;

    if(this.isActive) {
      this.$emit('navigationDropdown:open')
    }
  }

  onMouseLeave() {
    if(this.isFullWidth || window.innerWidth < 768) return;

    setTimeout(()=>{
      this.close();
    }, 300)
  }
}

export default NavigationDropdown;
