import { render, staticRenderFns } from "./Facts.vue?vue&type=template&id=5122f3ad&"
import script from "./Facts.vue?vue&type=script&lang=ts&"
export * from "./Facts.vue?vue&type=script&lang=ts&"
import style0 from "./Facts.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports