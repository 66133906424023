









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class Quote extends Vue {
  @Prop({default: ''})
  text: string;

  @Prop({default: 'is-white'})
  cssClass: string;

}

export default Quote;
