

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class Position extends Vue {
  @Prop({default: ()=>[]})
  data: any;

}

export default Position;
