

















import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {LocaleMessages} from "vue-i18n";

@Component
class CoverVideo extends Vue {
  @Prop({required: true})
  coverTitle: string | LocaleMessages;

  @Prop({required: true})
  coverText: string | LocaleMessages;
}

export default CoverVideo;
