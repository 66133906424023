












































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Position from "@/components/components/Position.vue";
import Cover from "@/components/components/Cover.vue";

@Component({
  components: {
    Cover,
    Position,
  }
})
class Careers extends Vue {

  currentIndex = 0;
  direction = 'forward'

  get coverData() {
    return  {
      img: require('@/assets/images/careers/join-team-cover.png'),
      title: this.$t(`careers.cover.title`),
      subtitle: this.$t(`careers.cover.subtitle`),
      cta: this.$t(`careers.cover.cta`),
    };
  }

  get transitionName() {
    return `${this.direction}-slide`
  }

  videos = [
    "https://www.youtube.com/embed/sJodW2ex4SY",
    "https://www.youtube.com/embed/3Fn8LbUXtuI",
    "https://www.youtube.com/embed/tVIhRSCdb_0",
    "https://www.youtube.com/embed/D2eLnglOQK0",
    "https://www.youtube.com/embed/D1mI3ifIQ00",
    "https://www.youtube.com/embed/hDnRNnOVSro",
    "https://www.youtube.com/embed/aZHOEyUalNs",
  ];

  nextSlide() {
    this.direction = 'forward';
    this.currentIndex = this.currentIndex === this.videos.length-1 ? 0 : this.currentIndex + 1;
  }

  prevSlide() {
    this.direction = 'backward';
    this.currentIndex = this.currentIndex === 0 ? this.videos.length-1 : this.currentIndex - 1;
  }
}

export default Careers;
