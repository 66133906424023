




















































































































































































































































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import LanguageSelector from "@/components/base/LanguageSelector.vue";
import NavigationDropdown from "@/components/base/NavigationDropdown.vue";

@Component ({
  components: {
    LanguageSelector,
    NavigationDropdown,
  }
})
class Navigation extends Vue {
  @Prop({default: false})
  translateUrl: boolean;

    isMobileActive: boolean = false;
    isServicesActive: boolean = false;
    navClass: string = 'is-main';
    navBlurred:  boolean = false;

    fullWidthDropdowns = ['services'];

    @Watch('isMobileActive')
    onMobileActive(isActive: boolean) {
      if (isActive) {
        document.documentElement.style.overflow = "hidden";
        return;
      }

      document.documentElement.style.overflow = "auto";
    }

    onBurgerClick() {
        this.isMobileActive = !this.isMobileActive;
    }

  setLanguage(lang: string) {
    if(lang === this.$i18n.locale) return;

    this.$i18n.locale = lang;

    if(this.translateUrl && !this.$route.fullPath.includes('tryNow')) {
      let newPath = `${this.$route.fullPath}/${lang}`.replace('//', '/');
      this.$router.push(newPath);
    }
  }

  onMouseLeave() {
      this.fullWidthDropdowns.forEach(d => {
        // @ts-ignore
        this.$refs[d]?.close();
      })
  }

  get currentLang() {
      return this.$i18n.locale
  }

  // onScroll() {
  //     if (window.pageYOffset > 120) {
  //       this.navClass = 'is-blurred';
  //       this.navBlurred = true;
  //       return
  //     }
  //
  //   this.navClass = 'is-main';
  //   this.navBlurred = false;
  // }

    created(): void {
      // window.addEventListener("scroll", this.onScroll);
      // document.documentElement.style.overflow = "auto";
    }

    //todo translations
    get routes() {
      if(this.$i18n.locale === 'nl') {
        return {
          home: '/',
          about: '/over-ons',
          partnerships: `/klanten/onze-partnerships`,
          testimonials: `/klanten/klant-succesverhalen`,
          values: '/over-ons/onze-waarden',
          team: `/over-ons/ons-team`,
          contact: '/nl/contact/',
          careers: '/werken-bij',
          customerExperience: '/oplossingen/customer-experience',
          customerExperienceEngagementStrategy: '/oplossingen/customer-experience/klantstrategie',
          customerExperienceTelephoneCustomerCare: '/oplossingen/customer-experience/end-to-end-telefonische-klantenservice',
          customerExperienceAppointmentOptimization: '/oplossingen/customer-experience/afspraak-optimalisatie-oplossingen',
          customerExperienceAiVoicebot: '/oplossingen/customer-experience/ai-voicebot',
          endToEnd: '/oplossingen/end-to-end-sales-support',
          endToEndEngagementStrategy: '/oplossingen/end-to-end-sales-support/klantstrategie',
          endToEndDataEnrichment: '/oplossingen/end-to-end-sales-support/data-verrijkingen-en-kwalificatie',
          endToEndLeadGeneration: '/oplossingen/end-to-end-sales-support/leadgeneratie-en-leadopvolging',
          endToEndLeadClosing: '/oplossingen/end-to-end-sales-support/lead-closing',
        }
      } else if(this.$i18n.locale === 'de') {
        return {
          home: '/',
          about: '/uber-uns',
          partnerships: `/kunden/unsere-kooperationspartner`,
          testimonials: `/kunden/erfolgsgeschichten-unserer-kunden`,
          values: `/uber-ons/unsere-werte`,
          team: `/uber-uns/unser-team`,
          customerExperience: '/losungen/kundenerlebnis',
          customerExperienceEngagementStrategy: '/losungen/kundenerlebnis/kundenstrategie',
          customerExperienceTelephoneCustomerCare: '/losungen/kundenerlebnis/end-to-end-kundenservice',
          customerExperienceAppointmentOptimization: '/losungen/kundenerlebnis/optimierung-der-terminvereinbarung',
          customerExperienceAiVoicebot: '/losungen/kundenerlebnis/ki-voicebot',
          endToEnd: '/losungen/end-to-end-vertriebsunterstutzung',
          endToEndEngagementStrategy: '/losungen/end-to-end-vertriebsunterstutzung/kundenstrategie',
          endToEndDataEnrichment: '/losungen/end-to-end-vertriebsunterstutzung/datenanreicherung-und-qualifizierung',
          endToEndLeadGeneration: '/losungen/end-to-end-vertriebsunterstutzung/lead-generierung-und-nachverfolgung-der-leads',
          endToEndLeadClosing: '/losungen/end-to-end-vertriebsunterstutzung/lead-closing',
          contact: '/de/contact/',
          careers: '/de/join-us/',
        }
      } else {
        return {
          home: '/',
          about: '/about',
          partnerships: `/clients/our-partnerships`,
          testimonials: `/clients/client-success-stories`,
          values: `/about-us/our-values`,
          team: `/about-us/our-team`,
          customerExperience: '/solutions/customer-experience',
          customerExperienceEngagementStrategy: '/solutions/customer-experience/customer-engagement-strategy',
          customerExperienceTelephoneCustomerCare: '/solutions/customer-experience/end-to-end-telephone-customer-care',
          customerExperienceAppointmentOptimization: '/solutions/customer-experience/appointment-optimization-solutions',
          customerExperienceAiVoicebot: '/solutions/customer-experience/ai-voicebot',
          endToEnd: '/solutions/end-to-end-sales-support',
          endToEndEngagementStrategy: '/solutions/end-to-end-sales-support/customer-engagement-strategy',
          endToEndDataEnrichment: '/solutions/end-to-end-sales-support/data-enrichment-and-qualification',
          endToEndLeadGeneration: '/solutions/end-to-end-sales-support/lead-generation-and-follow-up',
          endToEndLeadClosing: '/solutions/end-to-end-sales-support/lead-closing',
          contact: '/contact/',
          careers: '/join-us/',
        }
      }
    }

    mounted() {
      document.documentElement.style.overflow = "auto";

      if(this.$route.query.lang) {
        if(this.$route.query.lang.includes('nl')) {
          this.setLanguage('nl');
        } else if(this.$route.query.lang.includes('de')) {
          this.setLanguage('de');
        } else {
          this.setLanguage('en');
        }
      }

      if(this.$route.meta?.locale) {
        if(this.$route.meta?.locale.includes('nl')) {
          this.setLanguage('nl');
        } else if(this.$route.meta?.locale.includes('de')) {
          this.setLanguage('de');
        } else {
          this.setLanguage('en');
        }
      }
    }

  beforeDestroy() {
    //window.removeEventListener("scroll", this.onScroll)
  }
}
export default Navigation;
