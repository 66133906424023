











































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Navigation from "@/components/base/Navigation.vue";
import Facts from "@/components/components/Facts.vue";
import Service from "@/components/components/Service.vue";
import Quote from "@/components/components/Quote.vue";
import WorkInNumbers from "@/components/components/WorkInNumbers.vue";
import Cover from "@/components/components/Cover.vue";
import HomeBlock from "@/components/components/HomeBlock.vue";
import LearnMore from "@/components/components/LearnMore.vue";
import Testimonials from "@/components/components/Testimonials.vue";
import Values from "@/components/components/Values.vue";
import _ from 'lodash';

@Component({
  components: {
    Navigation,
    Facts,
    Service,
    Quote,
    WorkInNumbers,
    Cover,
    HomeBlock,
    LearnMore,
    Testimonials,
    Values,
  }
})
class Home extends Vue {

    images = {
        coverBg: '',
        contactBg: '',
    };

  dots = require('@/assets/images/dots-green.png');
  connectIcon = require('@/assets/images/contact-connect.png');

  debouncedHandleScroll = _.debounce(this.handleScroll, 1200);

  isElementInViewport = {
    values: false,
    testimonials: false,
    partnerships: false,
    contact: false,
  }

  get coverData(){
    return {
      img: require('@/assets/images/movements-marketing-cover.png'),
      title: this.$t(`home.title`),
      subtitle: this.$t(`home.subtitle`),
    };
  }

  get routesTranslated() {
    if(this.$i18n.locale === 'nl') {
      return {
        customerExperience: '/oplossingen/customer-experience',
        endToEnd: '/oplossingen/end-to-end-sales-support',
      }
    } else if(this.$i18n.locale === 'de') {
      return {
        customerExperience: '/losungen/kundenerlebnis',
        endToEnd: '/losungen/end-to-end-vertriebsunterstutzung',
      }
    } else {
      return {
        customerExperience: '/solutions/customer-experience',
        endToEnd: '/solutions/end-to-end-sales-support',
      }
    }
  }

  get services() {
    return  [
      {
        title: this.$t(`home.services.customerExperience.title`),
        text: this.$t(`home.services.customerExperience.text`),
        url: this.routesTranslated.customerExperience,
        imgSrc: require('@/assets/images/services/movements-customer-experience.png'),
        isLeft: false,
      },
      {
        title: this.$t(`home.services.endToEnd.title`),
        text: this.$t(`home.services.endToEnd.text`),
        url: this.routesTranslated.endToEnd,
        imgSrc: require('@/assets/images/services/movements-end-to-end-sales-support.png'),
        isLeft: true,
      },
    ];
  }

  get partnerships() {
    return [
      require('@/assets/images/clients/sazas.png'),
      require('@/assets/images/clients/dak.png'),
      require('@/assets/images/clients/diagnost.png'),
      require('@/assets/images/clients/scorito.png'),
      require('@/assets/images/clients/shypple.png'),
      require('@/assets/images/clients/abc_display.png'),
      require('@/assets/images/clients/oct8ne.png'),
      require('@/assets/images/clients/trans.png'),
      require('@/assets/images/clients/x2com.png'),
      require('@/assets/images/clients/aleger.png'),

      require('@/assets/images/clients/sazas.png'),
      require('@/assets/images/clients/dak.png'),
      require('@/assets/images/clients/diagnost.png'),
      require('@/assets/images/clients/scorito.png'),
      require('@/assets/images/clients/shypple.png'),
      require('@/assets/images/clients/abc_display.png'),
      require('@/assets/images/clients/oct8ne.png'),
      require('@/assets/images/clients/trans.png'),
      require('@/assets/images/clients/x2com.png'),
      require('@/assets/images/clients/aleger.png'),
    ]
  }

    activeOfficeIndex = 0;

    offices = [
        {
            key: 'sarajevo',
            title: 'Sarajevo',
            address: 'Marka Marulića 2B',
            postal: '71000 Sarajevo',
            country: 'Bosnia and Herzegovina',
            phone: '+31 85 210 37 55',
            mail: 'info@movementsmarketing.com'
        },
        {
            key: 'utrecht',
            title: 'Utrecht',
            address: 'Beresteinseweg 34',
            postal: '1217 TJ Hilversum',
            country: 'Netherlands',
            phone: '+31 85 210 37 55',
            mail: 'info@movementsmarketing.com'
        },
        {
            key: 'istanbul',
            title: 'Istanbul',
            address: 'Kagithane merkez mah. Seckin sok. (Dap Vadisi I Ofis nr.56 )',
            postal: 'Istanbul / Kagithane',
            country: 'Turkey',
            phone: '+31 85 210 37 55',
            mail: 'info@movementsmarketing.com'
        },
    ];


  scrollToAnchor () {
    console.log('scroll to ancor')
    this.$nextTick(() => {
      if (this.$route.meta?.element) {
        const $el = document.querySelector(this.$route.meta?.element);
        $el && window.scrollTo(0, $el.offsetTop - 120);
      }
    });
  }

  // async handleFormSubmit ($event) {
  //   const form = $event.target;
  //   const body = new URLSearchParams(new FormData(form))
  //   try {
  //     const res = await fetch(form.action, { method: 'POST', body })
  //     if (res.ok) {
  //       this.$router.push({ path: '/thank-you/general' })
  //     } else {
  //       throw res
  //     }
  //   } catch (err) {
  //     console.error(err)
  //     // you don't have an error page but maybe you should add one
  //   }
  // }

  declineFirstKeys(event: KeyboardEvent, parameters: string[]){
    // @ts-ignore
    if (parameters.includes(event.key) && event.target?.selectionStart === 0) event.preventDefault();
  }

  handleScroll() {
    // @ts-ignore
    const element = this.$router.history.current?.meta?.element;

    setTimeout(() => {
      // @ts-ignore
      if(element && !this.isElementInViewport[element.substring(1)]) {
        // @ts-ignore
        // const pathArray = this.$router.history.current.fullPath.split('/');
        // pathArray.pop();

        const state = { isRouterChange: true };
        window.history.pushState(state, '',  '/');
      }
    }, 200)
  }

  mounted() {
    this.scrollToAnchor();

    window.addEventListener('scroll', this.debouncedHandleScroll);

    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const elementId = entry.target.id;
            this.$set(this.isElementInViewport, elementId, entry.isIntersecting);
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.2,
        }
    );

    Object.keys(this.isElementInViewport).forEach(elementId => {
      const targetElement = document.getElementById(elementId);

      if (targetElement) {
        observer.observe(targetElement);
      }
    });
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.debouncedHandleScroll);
  }

    updated () {
      this.scrollToAnchor();
    }
}

export default Home;
