
















import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {LocaleMessages} from "vue-i18n";

@Component
class Cover extends Vue {
  @Prop({required: true})
  coverImage: string;

  @Prop({required: true})
  coverTitle: string | LocaleMessages;

  @Prop({required: true})
  coverSubtitle: string | LocaleMessages;

  @Prop({default: false})
  isGreen: boolean;

  @Prop({default: false})
  hasGrayBg: boolean;
}

export default Cover;
