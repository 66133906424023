





























import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import Testimonial from "@/components/components/Testimonial.vue";

@Component({
  components: {
    Testimonial
  }
})
class Testimonials extends Vue {
  currentIndex = 0;

  direction = 'forward'

  get testimonials() {
    return [
      {
        cover: require('@/assets/images/testimonials/diagnost.png'),
        company: `Diagnost-IQ`,
        companyDescription: this.$t(`home.testimonials.diagnost.companyDescription`),
        quote: this.$t(`home.testimonials.diagnost.quote`),
        quotePerson: this.$t(`home.testimonials.diagnost.quotePerson`),
        impact: this.$t(`home.testimonials.diagnost.impact`),
      },
      {
        cover: require('@/assets/images/testimonials/sazas.png'),
        company: `Sazas`,
        companyDescription: this.$t(`home.testimonials.sazas.companyDescription`),
        quote: this.$t(`home.testimonials.sazas.quote`),
        quotePerson: this.$t(`home.testimonials.sazas.quotePerson`),
        impact: this.$t(`home.testimonials.sazas.impact`),
      },
      {
        cover: require('@/assets/images/testimonials/shypple.png'),
        company: `Shypple`,
        companyDescription: this.$t(`home.testimonials.shypple.companyDescription`),
        quote: this.$t(`home.testimonials.shypple.quote`),
        quotePerson: this.$t(`home.testimonials.shypple.quotePerson`),
        impact: this.$t(`home.testimonials.shypple.impact`),
      },
      {
        cover: require('@/assets/images/testimonials/dak.png'),
        company: `Dak`,
        companyDescription: this.$t(`home.testimonials.dak.companyDescription`),
        quote: this.$t(`home.testimonials.dak.quote`),
        quotePerson: this.$t(`home.testimonials.dak.quotePerson`),
        impact: this.$t(`home.testimonials.dak.impact`),
      },
      {
        cover: require('@/assets/images/testimonials/scorito.png'),
        company: `Scorito`,
        companyDescription: this.$t(`home.testimonials.scorito.companyDescription`),
        quote: this.$t(`home.testimonials.scorito.quote`),
        quotePerson: this.$t(`home.testimonials.scorito.quotePerson`),
        impact: this.$t(`home.testimonials.scorito.impact`),
      },
      {
        cover: require('@/assets/images/testimonials/cargoplot.png'),
        company: ``,
        companyDescription: this.$t(`home.testimonials.cargoplot.companyDescription`),
        quote: this.$t(`home.testimonials.cargoplot.quote`),
        quotePerson: this.$t(`home.testimonials.cargoplot.quotePerson`),
        impact: this.$t(`home.testimonials.cargoplot.impact`),
      },
      {
        cover: require('@/assets/images/testimonials/oct8ne.png'),
        company: `Oct8ne`,
        companyDescription: this.$t(`home.testimonials.oct8ne.companyDescription`),
        quote: this.$t(`home.testimonials.oct8ne.quote`),
        quotePerson: this.$t(`home.testimonials.oct8ne.quotePerson`),
        impact: this.$t(`home.testimonials.oct8ne.impact`),
      },
      {
        cover: require('@/assets/images/testimonials/x2com.png'),
        company: `X2com`,
        companyDescription: this.$t(`home.testimonials.x2com.companyDescription`),
        quote: this.$t(`home.testimonials.x2com.quote`),
        quotePerson: this.$t(`home.testimonials.x2com.quotePerson`),
        impact: this.$t(`home.testimonials.x2com.impact`),
      },
      {
        cover: require('@/assets/images/testimonials/trans.png'),
        company: `Trans.eu`,
        companyDescription: this.$t(`home.testimonials.trans.companyDescription`),
        quote: this.$t(`home.testimonials.trans.quote`),
        quotePerson: this.$t(`home.testimonials.trans.quotePerson`),
        impact: this.$t(`home.testimonials.trans.impact`),
      },
      {
        cover: require('@/assets/images/testimonials/aleger.png'),
        company: `Aleger Global GmbH`,
        companyDescription: this.$t(`home.testimonials.alager.companyDescription`),
        quote: this.$t(`home.testimonials.alager.quote`),
        quotePerson: this.$t(`home.testimonials.alager.quotePerson`),
        impact: this.$t(`home.testimonials.alager.impact`),
      },
      {
        cover: require('@/assets/images/testimonials/abc-display.png'),
        company: `ABC Display`,
        companyDescription: this.$t(`home.testimonials.abcDisplay.companyDescription`),
        quote: this.$t(`home.testimonials.abcDisplay.quote`),
        quotePerson: this.$t(`home.testimonials.abcDisplay.quotePerson`),
        impact: this.$t(`home.testimonials.abcDisplay.impact`),
      },
    ];
  }

  get transitionName() {
    return `${this.direction}-slide`
  }

  nextSlide() {
    this.direction = 'forward';
    this.currentIndex = this.currentIndex === this.testimonials.length-1 ? 0 : this.currentIndex + 1;
  }

  prevSlide() {
    this.direction = 'backward';
    this.currentIndex = this.currentIndex === 0 ? this.testimonials.length-1 : this.currentIndex - 1;
  }
}

export default Testimonials;
