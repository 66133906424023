















import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import CaseStudy from "@/components/components/CaseStudy.vue";

@Component({
  components: {
    CaseStudy,
  }
})
class CaseStudies extends Vue {
  @Prop({required: true})
  title: string

  @Prop({required: true})
  caseStudies: {
    img: String,
    title: String,
    text: String,
  }[]


}
export default CaseStudies;
