


























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ServiceCover from "@/components/components/ServiceCover.vue";
import Facts from "@/components/components/Facts.vue";
import ServiceInformation from "@/components/components/ServiceInformation.vue";
import Quote from "@/components/components/Quote.vue";
import ServiceTrial from "@/components/components/ServiceTrial.vue";
import ServiceSales from "@/components/components/ServiceSales.vue";
import ServiceForm from "@/components/components/ServiceForm.vue";
import Cover from "@/components/components/Cover.vue";
import ServiceBlock from "@/components/components/ServiceBlock.vue";
import LearnMore from "@/components/components/LearnMore.vue";
import CaseStudies from "@/components/components/CaseStudies.vue";
import _ from 'lodash';

@Component({
  components: {
    LearnMore,
    CaseStudies,
    Cover,
    ServiceBlock,
    ServiceCover,
    Facts,
    ServiceInformation,
    Quote,
    ServiceTrial,
    ServiceSales,
    ServiceForm,
  }
})
class CustomerExperience extends Vue {


  dots = require('@/assets/images/dots-green.png');
  debouncedHandleScroll = _.debounce(this.handleScroll, 1200);

  isElementInViewport = {
    getStarted: false,
    engagementStrategy: false,
    telephoneCustomerCare: false,
    appointmentOptimization: false,
    aiVoicebot: false,
  }


  get coverData(){
    return {
      img: require('@/assets/images/services/customer_experience/customer-experience-cover.png'),
      title: this.$t(`services.customerExperience.cover.title`),
      subtitle: this.$t(`services.customerExperience.cover.subtitle`),
      cta: this.$t(`services.customerExperience.cover.cta`),
    };
  }

  get caseStudies(){
    return [
      {
        img:  require('@/assets/images/services/customer_experience/caseStudies/customer-experience-study-1.png'),
        title: this.$t(`services.customerExperience.caseStudies.study1.title`),
        text: this.$t(`services.customerExperience.caseStudies.study1.text`),
      },
      {
        img:  require('@/assets/images/services/customer_experience/caseStudies/customer-experience-study-2.png'),
        title: this.$t(`services.customerExperience.caseStudies.study2.title`),
        text: this.$t(`services.customerExperience.caseStudies.study2.text`),
      },
      {
        img:  require('@/assets/images/services/customer_experience/caseStudies/customer-experience-study-3.png'),
        title: this.$t(`services.customerExperience.caseStudies.study3.title`),
        text: this.$t(`services.customerExperience.caseStudies.study3.text`),
      },
    ]

  }

  //todo translations
  get routes() {
    if(this.$i18n.locale === 'nl') {
      return {
        getStarted: '/oplossingen/customer-experience/contact',
      }
    } else if(this.$i18n.locale === 'de') {
      return {
        getStarted: '/losungen/kundenerlebnis/contact',
      }
    } else {
      return {
        getStarted: '/solutions/customer-experience/contact',
      }
    }
  }

  get services() {
    return  [
      {
        title: this.$t(`services.customerExperience.engagementStrategy.title`),
        text: this.$t(`services.customerExperience.engagementStrategy.text`),
        imgSrc: require('@/assets/images/services/customer_experience/customer-experience-engagement.png'),
        isLeft: false,
        id: 'engagementStrategy'
      },
      {
        title: this.$t(`services.customerExperience.telephoneCustomerCare.title`),
        text: this.$t(`services.customerExperience.telephoneCustomerCare.text`),
        imgSrc: require('@/assets/images/services/customer_experience/customer-experience-telephone.png'),
        isLeft: true,
        id: 'telephoneCustomerCare'
      },
      {
        title: this.$t(`services.customerExperience.appointmentOptimization.title`),
        text: this.$t(`services.customerExperience.appointmentOptimization.text`),
        imgSrc: require('@/assets/images/services/customer_experience/customer-experience-appointment.png'),
        isLeft: false,
        id: 'appointmentOptimization'
      },
      {
        title: this.$t(`services.customerExperience.aiAssistant.title`),
        text: this.$t(`services.customerExperience.aiAssistant.text`),
        imgSrc: require('@/assets/images/services/customer_experience/customer-experience-ai.png'),
        isLeft: true,
        isHighlighted: true,
        cta: this.$t(`services.customerExperience.aiAssistant.cta`),
        url: `https://services.movementsmarketing.com/voicebot/`,
        id: 'aiVoicebot'
      },
    ];
  }

  handleScroll() {
    // @ts-ignore
    const element = this.$router.history.current?.meta?.element;
    const elementSubstring = element.substring(1);

    setTimeout(() => {
      // @ts-ignore
      if(element && !this.isElementInViewport[elementSubstring]) {
        // @ts-ignore
        const pathArray = this.$router.history.current.fullPath.split('/');
        pathArray.pop();

        console.log('IN IF', pathArray)

        const state = { isRouterChange: true };
        window.history.pushState(state, '',  pathArray.join('/'));
      }
    }, 200)
  }

  scrollToAnchor () {
    this.$nextTick(() => {
      if (this.$route.meta?.element) {
        const $el = document.querySelector(this.$route.meta?.element);
        $el && window.scrollTo(0, $el.offsetTop - 120);
      }
    });
  }

  mounted() {
    this.scrollToAnchor();

    window.addEventListener('scroll', this.debouncedHandleScroll);

    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const elementId = entry.target.id;
            this.$set(this.isElementInViewport, elementId, entry.isIntersecting);
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.2,
        }
    );

    Object.keys(this.isElementInViewport).forEach(elementId => {
      const targetElement = document.getElementById(elementId);

      if (targetElement) {
        observer.observe(targetElement);
      }
    });
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.debouncedHandleScroll);
  }

}

export default CustomerExperience;
