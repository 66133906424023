







































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import NavigationDropdown from "@/components/base/NavigationDropdown.vue";

@Component({
  components: {
    NavigationDropdown
  }
})
class LanguageSelector extends Vue {
  @Prop({default: true})
  translateUrl: boolean;

  isLanguagesActive = false;

  setLanguage(lang: string) {
    if(lang === this.$i18n.locale) return;

    this.$i18n.locale = lang;

    if(this.translateUrl && !this.$route.fullPath.includes('tryNow')) {
      let newPath = `${this.$route.fullPath}/${lang}`.replace('//', '/');
     this.$router.push(newPath);
    }
  }

  get currentLang() {
    return this.$i18n.locale
  }
}

export default LanguageSelector;
