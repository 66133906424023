









































































































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
class Footer extends Vue {
  @Prop({default: false})
  isGray: boolean;

  get policyLink() {
    return this.$i18n.locale === 'nl' ? 'https://drive.google.com/file/d/1vXHSUX6ixFlZud2xzOfZpWmKQRAA3tgB/view' : 'https://drive.google.com/file/d/13JrSBecSq-rMm0diCgUAUUkID1yI6_Vk/view';
  }

  get routes() {
    if(this.$i18n.locale === 'nl') {
      return {
        careers: '/werken-bij',
      }
    } else if(this.$i18n.locale === 'de') {
      return {
        careers: '/de/join-us/',
      }
    } else {
      return {
        careers: '/join-us/',
      }
    }
  }
}

export default Footer;
