
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class ServiceInformation extends Vue {
  @Prop({required: true})
  data: any;
}

export default ServiceInformation;
