




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class Service extends Vue {
  @Prop({default: ''})
  name: string;

  @Prop({default: ''})
  url: string;

  @Prop({default: ''})
  imgSrc: string;

}

export default Service;
