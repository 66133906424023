




































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class ServiceSales extends Vue {
  @Prop({default: ''})
  title: string;

  @Prop({default: () => []})
  blocks: any[];
}

export default ServiceSales;
