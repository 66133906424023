


























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
class WorkInNumbers extends Vue {

  workInNumbers = [
    { value: 598, current: 0, translationKey: 'home.numbers.projects' },
    { value: 9, current: 0, translationKey: 'home.numbers.languages' },
    { value: 3, current: 0, translationKey: 'home.numbers.offices' },
    { value: 4, current: 0, translationKey: 'home.numbers.teams' },
  ];

  animatedNumber(number: {value: number; current: number; translationKey: string}) {
    return `${Math.floor(number.current)}`;
  }

  startNumberAnimation() {
    this.workInNumbers.forEach((number) => {
      const timeout = 1500/number.value;
      const increment = number.value > 20 ? 2 : 1

      const intervalId = setInterval(() => {
        number.current += increment;

        if (number.current === number.value) {
          clearInterval(intervalId);
        }
      }, timeout);
    });
  }

  mounted () {
    // setTimeout(() => {
    //   this.startNumberAnimation();
    // }, 500);
  }
}

export default WorkInNumbers;
