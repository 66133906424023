

















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
class ThankYou extends Vue {

}

export default ThankYou;
