


























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
class HomeBlock extends Vue {
  @Prop({default: false})
  isLeft: String;

  @Prop({default: ''})
  name: String;

  @Prop({required: true})
  imgUrl: String;

  dots = require('@/assets/images/dots-green.png');
}

export default HomeBlock;
