






































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
class Testimonial extends Vue {
  @Prop({required: true})
  data:     {
    cover: String,
    company: String,
    companyDescription: String,
    quote: String,
    quotePerson: String,
    impact: String,
  }

  @Prop({required: true})
  index: Number

  @Prop({required: true})
  transitionName: string

  coverHeightOnLoad = 0;

  get coverHeight() {
    return `${this.coverHeightOnLoad}px` || '200px';
  }

  onImageLoad() {
    // @ts-ignore
    this.coverHeightOnLoad = this.$refs.testimonialCover?.clientHeight;
  }
}

export default Testimonial;
