
















































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
class PrivacyPolicy extends Vue {

}

export default PrivacyPolicy;
