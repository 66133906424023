





































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class ServiceForm extends Vue {
  @Prop({default: ''})
  title: string;

  @Prop({default: ''})
  subtitle: string;

  @Prop({default: ''})
  text: string;

  @Prop({default: ''})
  formName: string;

  @Prop({default: ''})
  serviceType: string;


  dots = require('@/assets/images/dots-green.png');

  declineFirstKeys(event: KeyboardEvent, parameters: string[]){
    // @ts-ignore
    if (parameters.includes(event.key) && event.target?.selectionStart === 0) event.preventDefault();
  }

}

export default ServiceForm;
