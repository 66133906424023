import en from './locales/en';
import de from './locales/de';
import nl from './locales/nl';


const locales = {
  en,
  de,
  nl,
};

export default locales;
