






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class ServiceCover extends Vue {
  @Prop({required: true})
  data: any;

  @Prop({default: null})
  customLink: string | null;
}

export default ServiceCover;
