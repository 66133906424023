












import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
class LearnMore extends Vue {
  @Prop({required: true})
  url: String;
}

export default LearnMore;
