












import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
class CaseStudy extends Vue {
  @Prop({required: true})
  data:     {
    img: String,
    title: String,
    text: String,
  }
}

export default CaseStudy;
