


























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
class ServiceBlock extends Vue {
  @Prop({default: false})
  isLeft: String;

  @Prop({default: false})
  isHighlighted: String;

  @Prop({default: ''})
  name: String;

  @Prop({required: true})
  imgUrl: String;

  dots = require('@/assets/images/dots-green.png');

  coverHeightOnLoad = null;

  get coverHeight() {
    return this.coverHeightOnLoad ? `${this.coverHeightOnLoad + 120}px` : 'auto';
  }

  onImageLoad() {
    // @ts-ignore
    this.coverHeightOnLoad = this.$refs.serviceBlockCover?.clientHeight;
  }
}

export default ServiceBlock;
