















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class Facts extends Vue {
  @Prop({default: ()=>[]})
  facts: string[];

}

export default Facts;
